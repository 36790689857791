<template>
  <div class="introduce">
    <h1 class="introduce__title">온라인 편지 쓰기</h1>
    <p class="introduce__text">
      온라인 편지를 통해 손편지의 감동을 재현해보세요!
      <!-- <br />이번 기회를 통해 감사함을 표현하는 시간을 만들어보아요. -->
    </p>
    <img
      class="introduce__letter__img"
      src="@/assets/introduce_letter.svg"
      alt="letter_image"
    />
    <img
      class="introduce__letter__mobile__img"
      src="@/assets/introduce_letter_mobile.svg"
      alt="introduce_letter_mobile"
    />
    <p class="introduce__text2">
      Android : 크롬 이용 권장
      <br />IOS : 사파리 이용 권장
    </p>
    <div class="button" @click="Write()">편지 작성</div>
    <!-- contents/leaderboard -->
    <ins
      class="adsbygoogle"
      style="
        display: inline-block;
        width: 320px;
        height: 50px;
        margin-top: 20px;
      "
      data-ad-client="ca-pub-1919598055512436"
      data-ad-slot="1678485541"
    ></ins>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Introduce",
  mounted() {
    (adsbygoogle = window.adsbygoogle || []).push({});
  },
  methods: {
    Write() {
      this.$router.push("/write");
    },
  },
};
</script>
<style scoped>
.introduce {
  width: 100%;
  min-height: 100%;

  background-color: white;

  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;

  padding: 40px 30px;
}

.introduce__title {
  font-family: "GmarketSansTTF";
  font-style: normal;
  font-weight: 400;
  font-size: 34px;

  margin: 12px 0px;

  word-break: keep-all;
}
.introduce__text {
  width: 340px;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  font-family: "GmarketSansTTFLight";

  color: #666666;

  word-break: keep-all;
}
.introduce__text2 {
  width: 340px;

  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;

  font-family: "GmarketSansTTFLight";

  color: #666666;

  word-break: keep-all;

  margin-top: -80px;
  margin-bottom: 20px;
}
.button {
  width: 330px;
  max-height: 56px;
  height: 56px;
  min-height: 56px;

  background-color: #ea5532;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  font-size: 16px;

  display: flex;
  justify-content: center;
  align-items: center;

  color: white;

  border-radius: 6px;

  font-family: "Noto Sans";

  cursor: pointer;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.introduce__letter__img {
  width: 100%;
}

.introduce__letter__mobile__img {
  display: none;
}
@media screen and (max-width: 440px) {
  .introduce__letter__mobile__img {
    display: block;
    width: 100%;
  }
  .introduce__letter__img {
    display: none;
  }
  .button {
    width: calc(100% - 18px);
    height: 50px;
    margin: 0px auto 0px auto;
  }
  .introduce__text2 {
    margin-top: 0px;
    margin-bottom: 10px;
  }
}
</style>
